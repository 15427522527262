import React from 'react'
import './Experience.css'
import {RiTeamFill} from 'react-icons/ri'
import {AiOutlineFundProjectionScreen, AiOutlineHtml5,AiFillGithub} from 'react-icons/ai'
import {GiTeacher} from 'react-icons/gi'
import {MdOutlineSecurity} from 'react-icons/md'
import {FiDatabase} from 'react-icons/fi'
import {SiPython, SiJavascript, SiJava,SiCss3,SiNodedotjs,SiMicrosoftazure,SiHeroku,SiMysql,SiPostgresql, SiWindows,SiMacos,SiLinux, SiCucumber} from 'react-icons/si'



const Experience = () => {
  return (
	<section id='experience'>
    <h5>What Skills I Have</h5>
    <h2>My Experience</h2>

    <div className='container experience__container'>
     
      
      <div className="technical">
      <h2>Technical Experience</h2>
      <div className='all__icons'>
      <SiPython className='experience__details-icon'/>
      <SiJavascript className='experience__details-icon'/>
      <SiJava className='experience__details-icon'/>
      <AiOutlineHtml5 className='experience__details-icon'/>
      <SiCss3 className='experience__details-icon'/>
      <SiNodedotjs className='experience__details-icon'/>
      <SiMicrosoftazure className='experience__details-icon'/>
      <SiHeroku className='experience__details-icon'/>
      <SiMysql className='experience__details-icon'/>
      <SiPostgresql className='experience__details-icon'/>
      <SiWindows className='experience__details-icon'/>
      <SiMacos className='experience__details-icon'/>
      <SiLinux className='experience__details-icon'/>
      <AiFillGithub className='experience__details-icon'/>
      <FiDatabase className='experience__details-icon'/>
      </div>
        <div className="experience__technical">
          <article className='experience__details'>
            <div><h3>Programming Languages</h3>
            <h4 className='text-light'>Python, Javascript, Java, Pascal</h4></div>
          </article>
          <article className='experience__details'>
            <div>
            <h3>Web Fundamentals</h3>
            <h4 className='text-light'>HTML, CSS, WTForms, GitHub, GitLab</h4>
            </div>
          </article>
          <article className='experience__details'>
            <div ><h3>Frameworks</h3>
            <h4 className='text-light'><p>Node.js, Express.js, JQuery, React, Django, Flask, Microsoft Azure, Heroku </p></h4></div>
          </article>
          <article className='experience__details'>
            <div><h3>Databases</h3>
            <h4 className='text-light'>SQL, MySQL, PostgreSQL, KQL, DBeaver</h4></div>
          </article>
          <article className='experience__details'>
            <div ><h3>Operating Systems</h3>
            <h4 className='text-light'>Windows, Linux, MacOS</h4></div>
          </article>
        </div>
        </div>
      {/* ------------------end of technical------------------*/}
      <div className="experience__overall">
      <h2>Overall Experience</h2>
      <div className='all__icons'>
      <RiTeamFill className='experience__details-icon'    />
      <SiCucumber className='experience__details-icon' />
      <AiOutlineFundProjectionScreen className='experience__details-icon'   />
      <MdOutlineSecurity className='experience__details-icon'    />
      <GiTeacher className='experience__details-icon'    />
      </div>
        <div className="experience__content">
          <article className='experience__details'>
            <div>
            <h3>Team Leading & Managment</h3></div>
          </article>
          <article className='experience__details'>
          <div><h3>Security</h3></div>
          </article>
          <article className='experience__details'>
          <div><h3>Training & Mentoring</h3></div>
          </article>
          <article className='experience__details'>
          <div><h3>Project Management</h3></div>
          </article>
          <article className='experience__details'>
          <h3>Problem Solving & Strategic Planning</h3>
          </article>
        </div>
      </div>
    </div>

  </section>
  )
}

export default Experience