import React from 'react'
import './Footer.css'
import {BsLinkedin, BsGithub} from 'react-icons/bs'
import logo from '../../assets/myLogo.png'
const Footer = () => {
  return (
  <footer>
    <img src={logo} alt="" className='footer__logo'/>

    <div className="footer__socials">
      <a href="https://www.linkedin.com/in/agamilior/" target="_blank"><BsLinkedin /></a>
      <a href="https://github.com/AgamiLior?tab=repositories" target="_blank"><BsGithub /></a>
    </div>

    <div className="footer__copyright">
      <small>&copy; This website was built by Lior Agami</small>
    </div>
    </footer>
    )
}


export default Footer