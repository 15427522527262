import React from 'react'
import './About.css'
import img from '../../assets/Lior.jpeg'
import {FaAward} from 'react-icons/fa'
import {BsCodeSquare} from 'react-icons/bs'
import {BiGitRepoForked} from 'react-icons/bi'


const About = () => {
  return (
	<section id='about'>
    <h5>Get To Know</h5>
    <h2>About Me</h2>
    <div className='container about__container'>
      <div className="about__me">
        <img src={img} className="about__me-image" alt="About Image" />
      </div>
      <div className="about__content">
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon' />
            <h5>Work Experience</h5>
            <small>14+ Years</small>
          </article>
          <article className='about__card'>
            <BsCodeSquare className='about__icon'/>
            <h5>Technical Experience</h5>
            <small>1+ Years</small>
          </article>
          <article className='about__card'>
            <BiGitRepoForked className='about__icon'/>
            <h5>Projects</h5>
            <small>70+</small>
          </article>
        </div>      
        <p className='about__content'>After working in the security sector for 14 years, I decided to finally pursue my passion of becoming a software engineer. I am a dedicated, hardworking, and passionate individual constantly looking to advance my expertise. As a security officer, police detective and naval captain, I have acquired the knowledge and skill set that allows me to quickly identify a problem and efficiently reach a solution. As a member of the Navy, being a team player was of the utmost importance, and I carry this lesson with me throughout life, as I believe working with others is the key to success.  As I have strengthened my coding abilities and the skills necessary to design applications as a full stack developer, I have come closer to fulfilling my career dream. I constantly strive for success, and I plan to continue learning and building my knowledge base. </p>    <a href="#contact" className='btn btn-primary'>Let's Talk</a>

      </div>
    </div>
  </section>
  )
}

export default About