import React from 'react'
import "./Header.css"
import CTA from './CTA'
import Lior2 from '../../assets/Lior2.jpg'
import HeaderSocials from './HeaderSocials'
const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <h5>Hello I am</h5>
        <h1>Lior Agami</h1>
        <h3 className="text-light">Software Engineer</h3>
        <CTA />
        <HeaderSocials />

        <div className="me">
          <img src={Lior2} alt="me" />
        </div>

        {/* <a href="#contact" className='scroll__down'>Scroll Down</a> */}
      </div>

    </header>
  )
}

export default Header