import React from 'react'
import './Portfolio.css'
import pasta from '../../assets/IsraeliBamitbach.jpeg'
import spotify from '../../assets/spotify.jpeg'

const data = [
  {
    id:1,
    image: pasta,
    title:'Israeli Bamitbach',
    github: 'https://github.com/AgamiLior/Israeli-Bamitbach',
    demo: 'https://israeli--bamitbach.herokuapp.com/'
  },
  {
    id:2,
    image: spotify,
    title:'Spotifinder',
    github: 'https://github.com/AgamiLior/spotifinder-new',
    demo: 'https://spotifinder-core.herokuapp.com/'
  }
]
function Portfolio() {
  // <img src={spotify} alt="Israeli Bamitbach" />
  return (
	<section id='portfolio'>
    <h5>My Recent Work</h5>
    <h2>Portfolio</h2>
    <div className="container portfolio__container">
      {
        data.map(({id, image, title, github, demo}) => {
          return(
            <article key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
            <img src={image} alt={title}  className='portfolio__image'/>
            </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
              <a href={github} className='btn' target='_blank'>Github Repo</a>        
              <a href={demo} className='btn btn-primary' target='_blank'>Live Demo</a>     
              </div>
       
          </article>
          )
        })
      }

    </div>
  </section>
  )
}

export default Portfolio