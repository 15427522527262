import React from 'react'
import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {AiOutlineSkype,AiOutlineWhatsApp} from 'react-icons/ai'
import { useRef } from 'react';
import emailjs from 'emailjs-com';


const Contact = () => {
  const form = useRef()
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_dzkhzop', 'template_4tg6ygh', form.current, 'MH7E9oOCjiSptDTKA')

    e.target.reset();
    alert('Your Message Has Been Sent!')
  };
  return (
	<section id='contact'>
    <h5>Get In Touch</h5>
    <h2>Contact Me</h2>
    <div className="container contact__container">
    <div className="contact__options">
     {/* <article className='contact__option'>
     <a href="mailto:agamil1019@gmail.com" target="_blank">
      <MdOutlineEmail className='contact__icon' />
      <h4></h4>
      Send an Email</a>
    </article> */}
     <article className='contact__option'>
     <a href="skype:live:agamilior25?chat" target="_blank">
      <AiOutlineSkype className='contact__icon'/>
      <h4></h4>
      Start Skype Chat</a>
    </article>
     <article className='contact__option'>
       <a href="https://wa.me/19292845515" target="_blank">
      <AiOutlineWhatsApp className='contact__icon'/>
      <h4></h4>
      Start WhatsApp Chat</a>
    </article>
    </div>
{/* END OF OPTIONS */}
<form ref={form} onSubmit={sendEmail}>
  <input type="text" name='name' placeholder='Enter Your Full Name' required/>
  <input type="email" name='email' placeholder='Enter Your Email' required/>
  <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
  <button type='submit' className='btn btn-primary'>Send Message</button>
</form>
    </div>
  </section>
  )
}

export default Contact
